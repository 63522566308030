export default function useProduct() {
  const tableColumns = [
    {
      key: "index",
      label: "STT",
    },

    {
      key: "imageUrl",
      label: "Ảnh",
    },
    {
      key: "link",
      label: "Link",
    },
    {
      key: "attributes",
      label: "Thuộc tính",
    },

    {
      key: "quality",
      label: "Số lượng",
      tdClass: "text-center",
    },

    {
      key: "quotePrice",
      label: "Giá Web (CNY)",
      tdClass: "text-center",
    },
    {
      key: "exchangePriceTotal",
      label: "Tổng Web (CNY)",
      tdClass: "text-center",
    },
    {
      key: "exchangeQuotePriceTotal",
      label: "Tổng Web (VND)",
      tdClass: "text-center",
    },
    {
      key: "note",
      label: "Ghi chú",
    },
  ];

  return {
    tableColumns,
  };
}
