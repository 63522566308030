<template>
  <div>
    <b-card no-body class="mb-0">
      <LoadingPage v-if="products.length == 0" />
      <div v-else class="m-2">
        <b-row>
          <b-col cols="12" md="10" lg="8" xl="7">
            <!-- Table Control -->
            <b-table
              ref="refUserListTable"
              :items="orders"
              :fields="tableColumns"
              responsive
              primary-key="id"
              thead-tr-class="hide_header"
              head-variant="dark-1"
              tbody-tr-class="tbody-tr-class"
              bordered
            >
              <template #empty>
                <div v-if="state.loading" class="text-center my-2">
                  <p>{{ localize["table_loading"] }}</p>
                </div>
                <div
                  v-if="!state.loading && totalRows === 0"
                  class="text-center my-2"
                >
                  {{ localize["table_empty"] }}
                </div>
              </template>

              <!-- Custom Column -->
              <template #cell(key)="{ item }">
                <div class="text-nowrap font-weight-bold">
                  {{ item.key }}
                </div>
              </template>

              <template #cell(value)="{ item }">
                <div
                  :class="
                    item.keys !== 'sumNote' && item.keys !== 'sumNoteAdmin'
                      ? 'text-nowrap'
                      : ''
                  "
                  style="color: black"
                >
                  <div v-if="item.keys === 'status'">
                    <b-row
                      style="
                        display: flex;
                        justify-items: center;
                        align-items: center;
                        cursor: pointer;
                      "
                    >
                      <b-col cols="12" style="flex: 1">
                        <b-badge
                          pill
                          style="padding: 8px"
                          class="text-capitalize"
                          :style="resolveStyleOrderStatus(item.value)"
                          :variant="`${resolveOrderStatus(item.value)}`"
                        >
                          {{ OrderStatusParse[item.value] }}
                        </b-badge>
                      </b-col>
                      <b-col
                        style="
                          width: 100%;
                          display: flex;
                          justify-content: right;
                        "
                      >
                        <div
                          v-if="isShowModal()"
                          style="
                            display: flex;
                            align-items: center;
                            padding: 2px;
                            color: #4d73ad;
                          "
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="16"
                            class="mr-0 mr-sm-50 cursor-pointer"
                            @click="() => handleShowModel(false)"
                            :disabled="state.loading"
                          />
                          <div
                            :disabled="state.loading"
                            @click="() => handleShowModel(false)"
                          >
                            Đổi trạng thái
                          </div>
                        </div></b-col
                      >
                    </b-row>
                  </div>
                  <div v-else>
                    <!-- cod TaoBao -->
                    <div v-if="item.keys === 'codTaoBao'">
                      <validation-observer ref="updateCodRef">
                        <b-row>
                          <validation-provider rules="" v-slot="{ errors }">
                            <b-input-group
                              class="input-group-merge"
                              style="width: 200px"
                            >
                              <b-form-input
                                style="height: 30px"
                                :disabled="
                                  updateOrderLoading &&
                                  codUpdate.key === 'codTaoBao'
                                "
                                id="codTaoBao"
                                v-model="order.codTaoBao"
                                @change="
                                  (val) => handleChangeCod('codTaoBao', val)
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{
                              errors.length > 0
                                ? "Mã taobao không hợp lệ"
                                : null
                            }}</small>
                          </validation-provider>
                        </b-row>
                      </validation-observer>
                    </div>

                    <div v-else>
                      <!-- note admin -->
                      <div v-if="item.keys === 'sumNoteAdmin'">
                        {{ item.value || "-" }}

                        <b-col
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                            cursor: pointer;
                          "
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              padding: 2px;
                              color: #4d73ad;
                            "
                          >
                            <feather-icon
                              icon="EditIcon"
                              size="16"
                              class="mr-0 mr-sm-50 cursor-pointer"
                              :style="resolveStyleOrderStatus(item.value)"
                              @click="handleUpdateOrderModal(false)"
                            />
                            <div @click="handleUpdateOrderModal(false)">
                              Cập nhật
                            </div>
                          </div></b-col
                        >
                      </div>
                      <div v-else>
                        <div v-if="item.keys === 'codTracking'">
                          <validation-observer>
                            <b-row>
                              <b-input-group
                                class="input-group-merge"
                                style="width: 200px"
                              >
                                <b-form-input
                                  style="height: 30px"
                                  :disabled="
                                    updateOrderLoading &&
                                    codUpdate.key === 'codTracking'
                                  "
                                  id="codTracking"
                                  v-model="order.codTracking"
                                  @change="
                                    (val) => handleChangeCod('codTracking', val)
                                  "
                                />
                              </b-input-group>
                            </b-row>
                          </validation-observer>
                        </div>

                        <div v-else>
                          <div v-if="item.keys === 'isPaid'">
                            <div
                              :style="
                                item.value === PaidEnum.Non
                                  ? 'color:red'
                                  : 'color:#29c76f'
                              "
                            >
                              {{ PaidEnumParse[item.value] }}
                            </div>
                          </div>
                          <div v-else>{{ item.value || "-" }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12" md="2" lg="4" xl="4">
            <!-- Status -->
            <b-button
              v-if="isShowModal()"
              @click="() => handleShowModel(false)"
              class="p-1"
              variant="primary
              mr-1"
              :disabled="state.loading"
            >
              <span class="ml-25 align-middle">{{
                resolveTextButton(order.status)
              }}</span>
            </b-button>

            <!-- Payment -->
            <b-button
              v-if="isShowPaymentModal()"
              @click="() => handleUpdateOrderModal(true)"
              class="p-1"
              :disabled="state.loading"
              variant="success mr-1"
            >
              <span class="ml-25 align-middle">Thanh toán</span>
            </b-button>

            <!-- Cancel -->
            <b-button
              v-if="isShowModal(true)"
              @click="() => handleShowModel(true)"
              class="p-1"
              variant="danger mr-1"
              :disabled="state.loading"
            >
              <span class="ml-25 align-middle">Huỷ</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row v-if="order.status >= OrderStatusEnum.Landing" class="m-2">
          <div style="font-size: 20px">Giá về tay</div>

          <div style="font-size: 20px; color: black">
            <b>
              :
              {{
                formatCurrency(
                  order.totalQuotePrice +
                    order.exchangeServiceCosts +
                    order.exchangeShipCosts +
                    order.totalOtherPrice
                )
              }}
              VNĐ
            </b>
          </div>
        </b-row>
      </div>
      <validation-observer
        ref="quotePriceRef"
        #default="{ invalid }"
        class="w-100"
      >
        <b-row
          v-if="
            products.length > 0 &&
            order &&
            order.status == OrderStatusEnum.Waiting_Quote
          "
          class="mr-2"
        >
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-end justify-content-end"
          >
            <b-button
              :disabled="invalid"
              @click="handleQuote"
              size="md"
              variant="outline-primary mr-1"
            >
              <Spinner v-if="quotePriceLoading" />
              <span v-else class="ml-25 align-middle">Cập nhật</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="m-2">
          <ProductComponent
            ref="productRef"
            v-if="order && products.length > 0"
            :products="products"
            :status="order.status"
            :orderId="order.orderId"
            :exchangeRate="order.exchangeRate"
            :handleUpdatePrice="getOrder"
          />
        </b-row>
      </validation-observer>
    </b-card>

    <Modal
      ref="modalRef"
      v-if="order && order.status !== OrderStatusEnum.Completed"
      :order="order"
      :refreshOrder="getOrder"
    />
    <UpdateOrderModal
      ref="updateOrderModalRef"
      v-if="order"
      :order="order"
      :handleRefreshAdminNote="handleRefreshAdminNote"
      :handleUpdateOrderInfo="getOrder"
    />
  </div>
</template>

<script>
import {
  OrderStatusEnum,
  OrderStatusParse,
  PaidEnum,
  PaidEnumParse,
} from "@/@core/utils/enums/order.enum";
import useTable from "@/base/table/table";
import { localize } from "@/helpers/localize";
import { formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import LoadingPage from "@core/components/LoadingPage";
import Spinner from "@core/components/Spinner";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import Modal from "./modal/Modal.vue";
import UpdateOrderModal from "./modal/UpdateOrder.vue";
import ProductComponent from "./product/Product.vue";
import useOrder from "./useOrder";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BBadge,
    BButton,
    ProductComponent,
    LoadingPage,
    Modal,
    ValidationObserver,
    ValidationProvider,
    Spinner,
    UpdateOrderModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      order: null,
      orders: [],
      products: [],
      totalOtherPrice: 0,
      codTaoBao: undefined,
      codUpdate: {
        key: null,
      },
    };
  },
  computed: {
    ...mapState({
      state: (s) => {
        return s.apps.order.order;
      },
      quotePriceLoading: (s) => s.apps.order.quotePrice.loading,
      updateOrderLoading: (s) => {
        return s.apps.order.updateOrder.loading;
      },
    }),
  },
  async created() {
    await this.getOrder();
  },
  methods: {
    ...mapActions({
      getOrders: "order/order/getOrder",
      updateOrder: "order/updateOrder/updateOrder",
    }),
    handleShowModel(isCancel) {
      this.$refs?.modalRef?.handleShowModal(isCancel);
    },
    async getOrder() {
      const { orderId } = this.$router.currentRoute.params;
      this.order = await this.getOrders({ orderId });
      this.totalOtherPrice = this.order?.totalOtherPrice || 0;
      this.orders = this.resolveKey(this.order);
      this.products = this.order?.products ?? [];
      this.$refs?.updateCodRef?.reset();
    },
    async handleUpdateOrder(data) {
      const { orderId } = this.order;
      const res = await this.updateOrder({
        orderId,
        ...data,
      });
      if (!res) return;
    },

    async handleChangeCod(key, value) {
      this.codUpdate.key = key;
      const { order } = this;
      const data = {
        [key]: value || null,
      };
      await this.handleUpdateOrder(data);
      this.showToastSuccess(
        null,
        `Cập nhật ${
          key === "codTaoBao" ? "mã taobao" : "mã tracking TQ"
        } thành công`
      );
      if (order?.status === OrderStatusEnum.Accepted) {
        await this.getOrder();
      }
    },

    handleRefreshAdminNote(sumNoteAdmin) {
      this.order.sumNoteAdmin = sumNoteAdmin;
      this.orders = this.resolveKey(this.order);
    },
    isShowModal(isCancel) {
      const { order } = this;
      const isShow = order && order?.status !== OrderStatusEnum.Completed;
      return isCancel
        ? isShow && order?.status !== OrderStatusEnum.Rejected
        : isShow;
    },
    isShowPaymentModal() {
      const { order } = this;
      return (
        order &&
        order?.status >= OrderStatusEnum.Quoted &&
        !order?.isPaid &&
        order?.status !== OrderStatusEnum.Rejected
      );
    },
    handleUpdateOrderModal(isPayment) {
      this.$refs?.updateOrderModalRef?.handleShowModal(isPayment);
    },

    handleQuote() {
      this.$refs?.productRef?.handleSubmitQuote();
    },
    resetForm() {
      this.$refs?.updateTotalOtherPriceRef?.reset();
    },
  },

  setup() {
    return {
      ...useTable(),
      ...useOrder(),
      ...useToastHandler(),
      localize,
      OrderStatusParse,
      OrderStatusEnum,
      formatCurrency,
      PaidEnum,
      PaidEnumParse,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.tbody-tr-class td[aria-colindex="1"] {
  width: 210px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
