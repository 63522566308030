<template>
  <!-- Table Control -->

  <b-table
    ref="refUserListTable"
    :items="products"
    :fields="tableColumns"
    :sort-by.sync="sortBy"
    :sort-desc.sync="isSortDirDesc"
    responsive
    primary-key="id"
    bordered
    hover
  >
    <template #empty>
      <div v-if="state.loading" class="text-center my-2">
        <p>{{ localize["table_loading"] }}</p>
      </div>
      <div v-if="!state.loading && totalRows === 0" class="text-center my-2">
        {{ localize["table_empty"] }}
      </div>
    </template>

    <!-- Custom header -->
    <template #head(quality)="data">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
          {{ data.label }}
        </div>
        <div class="d-flex justify-content-center">
          ( {{ formatCurrency(handleTotalPrice()["totalQuality"]) }} )
        </div>
      </div>
    </template>
    <template #head(quotePrice)="data">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
          {{ data.label }}
        </div>
        <div class="d-flex justify-content-center">
          ( {{ formatCurrency(handleTotalPrice()["quotePrice"]) }} ¥ )
        </div>
      </div>
    </template>
    <template #head(exchangePriceTotal)="data">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
          {{ data.label }}
        </div>
        <div class="d-flex justify-content-center">
          ( {{ formatCurrency(handleTotalPrice()["totalQuotePrice"]) }} ¥ )
        </div>
      </div>
    </template>
    <template #head(exchangeQuotePriceTotal)="data">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
          {{ data.label }}
        </div>
        <div class="d-flex justify-content-center gap-1">
          <div>
            (
            {{ formatCurrency(handleTotalPrice()["totalExchangeQuotePrice"]) }}
            <span class="text-lowercase">đ</span>
            )
          </div>
        </div>
      </div>
    </template>

    <!-- Custom Column -->

    <template #cell(index)="{ index }">
      <span>{{ index + 1 }}</span>
    </template>

    <template #cell(attributes)="{ item }">
      <div style="min-width: 120px">
        {{ handleShowAttributes(item.attributes) }}
      </div>
    </template>

    <template #cell(note)="{ item }">
      <div v-if="item.note" style="min-width: 250px">
        {{ item.note }}
      </div>
      <div v-else>{{ "-" }}</div>
    </template>

    <template #cell(link)="{ item }">
      <div><a :href="item.link">Link</a></div>
    </template>

    <template #cell(exchangePriceTotal)="{ item }">
      <div style="min-width: 120px">
        {{
          formatCurrency(
            Number(
              (handleParseCurrency(item.quotePrice || 0) || item.quotePrice) *
                (item.quality || 0)
            ).toFixed(2)
          )
        }}
        ¥
      </div>
    </template>

    <template #cell(exchangeQuotePriceTotal)="{ item }">
      <div style="min-width: 120px">
        {{
          formatCurrency(
            Number(
              (handleParseCurrency(item.quotePrice || 0) || item.quotePrice) *
                (item.quality || 0) *
                (exchangeRate || 0)
            ).toFixed(2)
          )
        }}
        đ
      </div>
    </template>

    <template #cell(quality)="{ item }">
      <validation-provider
        v-if="status == OrderStatusEnum.Waiting_Quote"
        rules="required|numeric"
        v-slot="{ errors }"
      >
        <b-input-group class="input-group-merge" style="width: 150px">
          <b-form-input
            style="height: 30px"
            id="quality"
            v-model="item.quality"
          />
          <b-input-group-append is-text style="height: 30px"
            >¥</b-input-group-append
          >
        </b-input-group>
        <small class="text-danger">{{
          errors.length > 0 ? "Số lượng không hợp lệ!" : null
        }}</small>
      </validation-provider>
      <div style="min-width: 75px" v-else>{{ item.quality }}</div>
    </template>

    <template #cell(quotePrice)="{ item }">
      <validation-provider
        v-if="status == OrderStatusEnum.Waiting_Quote"
        rules="required"
        v-slot="{ errors }"
      >
        <b-input-group class="input-group-merge" style="width: 150px">
          <b-form-input
            v-currency="config"
            style="height: 30px"
            id="totalQuotePrice"
            v-model="item.quotePrice"
          />

          <b-input-group-append is-text style="height: 30px"
            >¥</b-input-group-append
          >
        </b-input-group>
        <small class="text-danger">{{
          errors.length > 0 ? "Giá không hợp lệ!" : null
        }}</small>
      </validation-provider>
      <div v-else>{{ formatCurrency(item.quotePrice) }} ¥</div>
    </template>

    <template #cell(imageUrl)="{ item }">
      <BAvatar rounded size="50" :src="item.imageUrl" />
    </template>
  </b-table>
</template>

<script>
import { OrderStatusEnum } from "@/@core/utils/enums/order.enum";
import useTable from "@/base/table/table";
import { localize } from "@/helpers/localize";
import { functionProp, numberProp, stringProp } from "@/helpers/props";
import { formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import useProduct from "./useProduct";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    BButton,
    BAvatar,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    products: {
      type: Array,
      default: [],
    },
    status: numberProp(),
    orderId: stringProp(),
    exchangeRate: numberProp(),
    handleUpdatePrice: functionProp(),
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (s) => {
        return s.apps.order.quotePrice.loading;
      },
    }),
    config() {
      return {
        currency: null,
        precision: { min: 0, max: 2 },
      };
    },
  },

  methods: {
    ...mapActions({
      updateQuotePriceProduct: "order/quotePrice/updateQuotePriceProduct",
    }),
    async handleSubmitQuote() {
      const { orderId } = this;
      const quotePriceProducts = this.products.map((product) => {
        return {
          productId: product.productId,
          quotePrice: Number(
            this.handleParseCurrency(product.quotePrice) || product.quotePrice
          ),
          quality: Number(product.quality),
        };
      });

      const res = await this.updateQuotePriceProduct({
        orderId,
        quotePriceProducts,
      });
      if (!res) return;
      // this.handleUpdatePrice();
      this.showToastSuccess(null, "Báo giá thành công");
    },
    handleParseCurrency(amount) {
      return this.$parseCurrency(amount, {
        locale: "en",
        currency: null,
      });
    },
    handleTotalPrice() {
      let quotePrice = 0,
        totalQuality = 0,
        totalQuotePrice = 0,
        totalExchangeQuotePrice = 0;

      this.products?.forEach((product) => {
        totalQuality += Number(product.quality || 0);

        quotePrice += Number(
          this.handleParseCurrency(product.quotePrice) || product.quotePrice
        );

        totalQuotePrice += Number(
          (this.handleParseCurrency(product.quotePrice || 0) ||
            product.quotePrice) * (product.quality || 0)
        );

        totalExchangeQuotePrice += Number(
          (this.handleParseCurrency(product.quotePrice || 0) ||
            product.quotePrice) *
            (product.quality || 0) *
            this.exchangeRate
        );
      });

      return {
        totalQuality,
        quotePrice: +Number(quotePrice).toFixed(2),
        totalQuotePrice: +Number(totalQuotePrice).toFixed(2),
        totalExchangeQuotePrice: +Number(totalExchangeQuotePrice).toFixed(2),
      };
    },
    resetForm() {
      this.$refs.changeExchangeRateRef.reset();
      this.$refs.modal.hide();
    },
    handleShowAttributes(attributes) {
      try {
        return JSON.parse(attributes).orgName;
      } catch (_) {
        return "-";
      }
    },
  },
  setup() {
    return {
      ...useTable(),
      ...useProduct(),
      ...useToastHandler(),
      localize,
      OrderStatusEnum,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vs__selected {
  max-width: 100px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
