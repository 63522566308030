import { OrderStatusEnum, PaidEnumParse } from "@/@core/utils/enums/order.enum";
import { formatCurrency } from "@/helpers/untils";
import { ref, watch } from "@vue/composition-api";

export default function useUsers() {
  const statusFilter = ref(null);
  const refUsers = ref(null);
  const sortBy = ref(null);
  const isSortDirDesc = ref(null);

  const tableColumns = [
    {
      key: "key",
      thStyle: { display: "none" },
    },
    {
      key: "value",
      thStyle: { display: "none" },
    },
  ];

  const statusOptions = [
    { label: "Active", value: 0 },
    { label: "Deleted", value: 1 },
  ];
  watch([statusFilter], () => {
    refUsers.value.refresh();
  });

  const resolveOrderStatus = (status) => {
    switch (status) {
      case OrderStatusEnum.Waiting_Quote:
        return "secondary";
      case OrderStatusEnum.Quoted:
        return "dark";
      case OrderStatusEnum.Accepted:
        return "primary";
      case OrderStatusEnum.Rejected:
        return "danger";
      case OrderStatusEnum.Processing:
        return "info";
      case OrderStatusEnum.Delivery:
        return "warning";
      case OrderStatusEnum.Completed:
        return "success";
    }
  };

  const resolveStyleOrderStatus = (status) => {
    switch (status) {
      case OrderStatusEnum.Landing:
        return "background-color: #FF00FF; color: #FFFFFF";
    }
  };

  const keyParse = {
    orderId: "Mã đơn hàng",
    fullName: "Tên KH",
    codTaoBao: "Mã taobao",
    codTracking: "Mã tracking TQ",
    exchangeRate: "Tỉ giá (VNĐ/CNY)",
    exchangeShipCosts: "Phí vận chuyển (TQ)",
    exchangeServiceCosts: "Phí dịch vụ",
    totalQuotePrice: "Giá báo",
    totalSystemQuotePrice: "Tổng tiền",
    totalOtherPrice: "Tiền cân",
    status: "Trang thái",
    isPaid: "Trang thái thanh toán",
    sumNote: "Ghi chú Khách hàng",
    sumNoteAdmin: "Ghi chú quản trị viên",
  };
  const resolveKey = (obj) => {
    if (!obj) return [];
    const {
      status,
      totalQuotePrice,
      exchangeServiceCosts,
      exchangeShipCosts,
      exchangeRate,
      totalExchangePrice,
    } = obj;

    const totalSystem =
      totalQuotePrice + exchangeServiceCosts + exchangeShipCosts ||
      totalExchangePrice;

    obj["totalSystemQuotePrice"] = `${formatCurrency(
      totalSystem
    )} VNĐ ~ (${formatCurrency(
      Number(totalSystem / exchangeRate).toFixed(2)
    )} NDT)`;

    return Object.keys(keyParse)
      .map((key) => {
        let value = obj[key];

        // currency
        if (
          key === "totalPrice" ||
          key === "totalExchangePrice" ||
          key === "totalQuotePrice" ||
          key === "totalQuoteExchangePrice" ||
          key === "totalOtherPrice" ||
          key === "exchangeRate"
        ) {
          value = obj[key]
            ? `${formatCurrency(obj[key])} đ`
            : `${key == "totalOtherPrice" ? "0 đ" : "-"}`;
        }

        // service costs
        if (key === "exchangeServiceCosts") {
          value = obj[key]
            ? `${formatCurrency(obj[key])} VNĐ ~ ${
                formatCurrency(obj["serviceCosts"])
                  ? `(${formatCurrency(
                      Number(obj[key] / exchangeRate).toFixed(2)
                    )} NDT ~ ${formatCurrency(obj["serviceCosts"])}% )`
                  : ""
              }`
            : "-";
        }

        // Ship costs
        if (key === "exchangeShipCosts") {
          value = obj[key]
            ? `${formatCurrency(obj[key])} VNĐ ~ (${formatCurrency(
                Number(obj["shipCosts"]).toFixed(2)
              )} NDT)`
            : "-";
        }

        // weight costs
        if (key === "totalOtherPrice") {
          value = obj[key]
            ? `${formatCurrency(obj[key])} VNĐ ${
                obj["otherWeight"]
                  ? `( ${formatCurrency(
                      obj["otherWeight"]
                    )} KG * ${formatCurrency(obj["otherPricePerWeight"])} VNĐ )`
                  : ""
              }`
            : "-";
        }

        if (key === "isPaid") {
          value = !!obj[key] ? 1 : 0;
        }
        if (key === "totalPrice") value = `${obj[key]} ¥ `;
        return {
          keys: key,
          key: keyParse[key],
          value: value,
        };
      })
      .filter((k) => !!k);
  };

  const resolveTextButton = (status) => {
    switch (status) {
      case OrderStatusEnum.Waiting_Quote:
        return "Báo giá";
      case OrderStatusEnum.Quoted:
        return "Đặt hàng";
      case OrderStatusEnum.Accepted:
        return "Nhập hàng";
      case OrderStatusEnum.Rejected:
        return "Báo giá lại";
      case OrderStatusEnum.Processing:
        return "Nhập kho";
      case OrderStatusEnum.Landing:
        return "Hoàn tất";
      default:
        return "-";
    }
  };

  return {
    tableColumns,
    statusOptions,
    statusFilter,
    sortBy,
    isSortDirDesc,

    refUsers,
    resolveOrderStatus,
    resolveStyleOrderStatus,
    resolveKey,
    resolveTextButton,
  };
}
